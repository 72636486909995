import React from "react";
import { Alert, AlertTitle } from "@mui/material";

const PriceUpdateBanner = () => {
  return (
    <Alert severity="info" sx={{ mb: 2 }}>
      <AlertTitle>
        <strong>Important Update: Price Change from 1st August 2024</strong>
      </AlertTitle>
      We wanted to let you know about a slight change in our prices that has
      taken effect from 1st August 2024:
      <ul>
        <li>
          <strong>30 minute massage:</strong> $55
        </li>
        <li>
          <strong>60 minute massage:</strong> $100
        </li>
      </ul>
      This change helps us continue to provide you with the highest quality
      service and care. We are grateful for your understanding and ongoing
      support.
    </Alert>
  );
};

export default PriceUpdateBanner;
